<template>
  <div>
    <b-row>
      <b-col>
        <header-slot
          no-border-bottom
          :clients-search="true"
          :global-search="true"
        >
          <template #actions>
            <div class="w-100 d-flex justify-content-end" style="gap: 15px">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button variant="outline-info">GLOBAL SEARCH</b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchText"
                  @keyup.enter="search"
                  placeholder="Search by Name, Account or the last 4 digits of Phone number"
                  style="height: auto"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="search">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-button
                variant="success"
                class="btn-black"
                @click="openModalNewLetter"
              >
                <feather-icon icon="PlusIcon" /><span>Create</span>
              </b-button>
            </div>
          </template>
        </header-slot>
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: $route.matched[1].meta.routeToSend }"
            exact
            exact-active-class="active"
            :active="isToSendTab"
            :link-classes="['px-3', bgTabsNavs]"
            >To Send
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.matched[1].meta.routePending }"
            exact
            exact-active-class="active"
            :active="isPendingTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Pending
            <span v-if="counterPendingRL > 0 && moduleId == 28" class="ml-1">
              <feather-icon
                icon
                :badge="counterPendingRL > 99 ? '99+' : counterPendingRL"
                badge-classes="badge-danger"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.matched[1].meta.routeCompleted }"
            exact
            exact-active-class="active"
            :active="isCompletedTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Completed
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.matched[1].meta.routeDeleted }"
            exact
            exact-active-class="active"
            :active="isDeletedTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Deleted
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.matched[1].meta.routeExpired }"
            exact
            exact-active-class="active"
            :active="isExpiredTab"
            :link-classes="['px-3', bgTabsNavs]"
            >Expired
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <modal-create-round-letter
      v-if="modalNewLetters"
      @updateTable="closeModalLetter"
      @hideModal="modalNewLetters = false"
    />

    <modal-global-search
      v-if="showModalGlobalSearch"
      :search-text="searchText"
      :role-id="currentUser.role_id"
      :program-id="programId"
      @closeModal="showModalGlobalSearch = false"
    />
  </div>
</template>

<script>
// MODALS
import ModalCreateRoundLetter from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalCreateRoundLetter.vue";
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

// STORE
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ModalCreateRoundLetter,
    ModalGlobalSearch,
  },
  data() {
    return {
      modalNewLetters: false,
      searchText: "",
      showModalGlobalSearch: false,
      programId: 7,
    };
  },
  computed: {
    ...mapGetters({
      returnedLetters:
        "SpecialistsDigitalRoundLettersStore/G_COUNTER_RETURNED_ROUND_LETTERS",
      counterPendingRL:
        "SpecialistsDigitalRoundLettersStore/G_COUNTER_PENDING_ROUND_LETTERS",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isPendingTab() {
      return this.$route.name == this.$route.matched[1]?.meta?.routePending;
    },
    isToSendTab() {
      return this.$route.name == this.$route.matched[1]?.meta?.routeToSend;
    },
    isCompletedTab() {
      const { routeCompletedInCorrespondence, routeCompletedSent } =
        this.$route.matched[2].meta;
      return [routeCompletedInCorrespondence, routeCompletedSent].includes(
        this.$route.name
      );
    },
    isDeletedTab() {
      return this.$route.name == this.$route.matched[1]?.meta?.routeDeleted;
    },
    isExpiredTab() {
      return this.$route.name == this.$route.matched[1]?.meta?.routeExpired;
    },
  },
  async created() {
    await this.A_COUNTER_PENDING_ROUND_LETTERS();
  },
  methods: {
    openModalNewLetter() {
      this.modalNewLetters = true;
    },
    closeModalLetter() {
      this.A_UPDATE_TABLE(true);
      this.modalNewLetters = false;
    },
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
      A_GLOBAL_SEARCH_CLIENTS: "clients-store/A_GLOBAL_SEARCH_CLIENTS",
      A_COUNTER_PENDING_ROUND_LETTERS:
        "SpecialistsDigitalRoundLettersStore/A_COUNTER_PENDING_ROUND_LETTERS",
    }),
    async search() {
      this.showModalGlobalSearch = true;
    },
  },
};
</script>
